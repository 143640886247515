<template>
  <table class="fpw-injury-report tw-border-0 tw-w-full tw-text-xs">
    <tr v-if="withHeading">
      <td class="tw-w-12"><img :src="teamLogo(team.logo)" :alt="team.fullName" :title="team.fullName" /></td>
      <td class="tw-text-left tw-font-kanit tw-uppercase tw-text-xl" colspan="7">{{ team.fullName }}</td>
    </tr>
    <tr class="tw-font-kanit tw-uppercase tw-font-medium tw-text-xs">
      <td class="tw-w-12">POS</td>
      <td class="tw-text-left tw-w-1/3">Name</td>
      <td v-if="!isTable"></td>
      <td v-if="isTable" class="tw-text-left tw-w-1/3">Injury</td>
      <td class="tw-w-10">{{ practice.day1 }}</td>
      <td class="tw-w-10">{{ practice.day2 }}</td>
      <td class="tw-w-10">{{ practice.day3 }}</td>
      <td class="tw-w-10"></td>
      <td class="tw-text-left tw-w-32">Status</td>
    </tr>
    <tr v-for="player in players(team)" :key="player.playerId">
      <td>{{ player.position }}</td>
      <td class="tw-text-left">
        <div class="fpw-player">
          <span v-if="isNumeric(player.playerId)"
            ><a :href="playerUrl(player)">{{ player.name }}</a></span
          >
          <span v-else>{{ player.name }}</span>
        </div>
        <div v-if="!isTable" class="fpw-injury tw-text-secondary-400 tw-italic">{{ player.injury }}</div>
      </td>
      <td v-if="!isTable"></td>
      <td v-if="isTable" class="tw-text-left">{{ player.injury }}</td>
      <td>{{ player[practice.day1.toLowerCase()] || '-' }}</td>
      <td>{{ player[practice.day2.toLowerCase()] || '-' }}</td>
      <td>{{ player[practice.day3.toLowerCase()] || '-' }}</td>
      <td></td>
      <td class="tw-text-left" :data-status="player.status" :data-current-status="player.currentStatus" :data-game-status="player.gameStatus">
        <div class="tw-flex tw-flex-row tw-justify-start tw-items-center">
          <div class="tw-w-2 tw-h-2 tw-rounded-full tw-mr-1" :class="playerStatus(player).toLowerCase()" ></div>
          <div>{{ playerStatus(player) }}</div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import slugify from '@fantasypoints/slugify';

export default {
  name: 'InjuryReportDetails',
  props: {
    team: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    gameday: String,
  },
  computed: {
    withHeading() {
      return !this.$props.options || (this.$props.options && this.$props.options.target !== 'table');
    },
    isTable() {
      return this.$props.options && this.$props.options.target === 'table';
    },
    practice() {
      switch (this.$props.gameday.toLowerCase()) {
        case 'monday':
          return { day1: 'Thu', day2: 'Fri', day3: 'Sat' };
        case 'thursday':
          return { day1: 'Mon', day2: 'Tue', day3: 'Wed' };
        case 'friday':
        case 'saturday':
          return { day1: 'Tue', day2: 'Wed', day3: 'Thu' };
        case 'sunday':
        default:
          return { day1: 'Wed', day2: 'Thu', day3: 'Fri' };
      }
    },
  },
  methods: {
    teamLogo(logo) {
      return `${logo}?size=30x30`;
    },
    players(team) {
      const ignoredInjuries = [
        'Rest',
        'Vet Rest',
        'NIR-Rest',
        'NIR (Rest)',
        'NIR - Rest',
        'NIR- Resting Veteran',
        'NIR - Resting Veteran',
        'Not Injury Related-Rest',
        'Not Injury-Related — Rest',
        'Not Injury Related - Rest',
        'Not Injury Related - Resting Vet',
        'Non-injury/resting veteran',
      ];
      return team.injuryReport.filter((p) => !ignoredInjuries.includes(p.injury) && Number(p.playerId) > 0);
    },
    isNumeric(playerId) {
      return Number(playerId) > 0;
    },
    playerUrl(player) {
      return `/nfl/players/${player.playerId}/${slugify.generate(player.name)}`;
    },
    playerStatus(player) {
      if (player.gameStatus === 'Out') {
        return player.gameStatus;
      } else if (player.status) {
        return player.status;
      } else if (!player.status && !player.currentStatus && (player.mon !== 'false' || player.tue !== 'false' || player.wed !== 'false' || player.thu !== 'false' || player.fri !== 'false' || player.sat !== 'false' || player.sun !== 'false')) {
        return 'Probable';
      } else {
        return player.currentStatus
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fpw-injury-report {
  th {
    padding: 2px 5px;
    font-weight: 500;
    background-color: #fff;
    color: #000;
  }
  td {
    vertical-align: top;
    padding: 2px 5px;
    text-align: center;

    &.tw-text-left {
      text-align: left;
    }
  }

  .out {
    background-color: #cc3333;
  }
  .doubtful {
    background-color: #e67c73;
  }
  .questionable {
    background-color: #f6b26b;
  }
  .probable {
    background-color: #6bd5f6;
  }
  .healthy {
    background-color: #93c47d;
  }
}
</style>
