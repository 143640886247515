<template>
  <div>
    <component v-if="withHeading" :is="tag" :id="headingSlug" :data-title="headingText" class="fpw-wx-heading tw-font-kanit tw-uppercase tw-pt-2">{{ headingText }}</component>
    <div v-if="withDate" class="fpw-wx-updated-date tw-text-xs tw-italic">{{ lastUpdated }}</div>
    <div v-if="forecast && forecast.hourly.length > 0">
      <div class="tw-flex tw-flex-row fpw-weather-report tw-py-3 tw-text-xs md:tw-text-sm">
        <div class="tw-w-full tw-text-center tw-p-1">
          <div class="tw-h-10"></div>
          <div class="tw-h-12"></div>
          <div class="tw-h-8" title="Temperature"><Icon icon="mdi:temperature-fahrenheit" size="1.5" /></div>
          <div class="tw-h-8" title="Feels Like"><Icon icon="fa:thermometer-3" size="1.5" /></div>
          <div class="tw-h-8" title="Humidity"><Icon icon="carbon:humidity" size="1.5" /></div>
          <div class="tw-h-8" title="Dew Point"><Icon icon="carbon:dew-point-filled" size="1.5" /></div>
          <div class="tw-h-8" title="Chance of Precipitation"><Icon icon="wi:raindrops" size="1.5" /></div>
          <div class="tw-h-4" title="Wind"><Icon icon="fluent:weather-squalls-24-regular" size="1.5" /></div>
          <div class="tw-h-6"></div>
        </div>
        <div v-for="(wx, index) in forecast.hourly" :key="index" class="tw-w-full tw-text-center tw-p-1" :class="index > 1 && index < 5 ? 'gametime-forecast' : 'forecast'">
          <div class="tw-h-10 tw-text-xs tw-font-kanit tw-font-medium">{{ formatDate({ date: wx.date }) }}<br>{{ ampm({ date: wx.date }) }} ET</div>
          <div class="tw-h-12" :title="wx.condition"><Icon :icon="getIcon(wx.code, wx.isDay)" size="2" /></div>
          <div class="tw-h-8">{{ Math.floor(wx.temp).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ Math.floor(wx.feelsLike).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ wx.humidity }}%</div>
          <div class="tw-h-8">{{ Math.floor(wx.dewpoint).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ wx.precip.toFixed(0) }}%</div>
          <div class="tw-h-4">{{ wx.windDirection }}</div>
          <div class="tw-h-6">
            <span v-if="wx.windGust.toFixed(0) > wx.windSpeed.toFixed(0)">{{ wx.windSpeed.toFixed(0) }}-{{ wx.windGust.toFixed(0) }}</span>
            <span v-else>{{ wx.windSpeed.toFixed(0) }}</span> mph
          </div>

        </div>
      </div>
    </div>
    <div v-else>
      <div class="tw-w-full tw-py-2 tw-text-left tw-text-sm">
        Currently viewing the daytime forecast for game day. Hourly forecasts typically become available within three (3) days of game day. 
      </div>
      <div class="tw-flex tw-flex-row fpw-weather-report tw-pb-3 tw-text-xs md:tw-text-sm">
        <div class="tw-w-2/12 tw-text-center tw-p-1">
          <div class="tw-h-10"></div>
          <div class="tw-h-12"></div>
          <div class="tw-h-8" title="Temperature"><Icon icon="mdi:temperature-fahrenheit" size="1.5" /></div>
          <div class="tw-h-8" title="Feels Like"><Icon icon="fa:thermometer-3" size="1.5" /></div>
          <div class="tw-h-8" title="Humidity"><Icon icon="carbon:humidity" size="1.5" /></div>
          <div class="tw-h-8" title="Dew Point"><Icon icon="carbon:dew-point-filled" size="1.5" /></div>
          <div class="tw-h-8" title="Chance of Precipitation"><Icon icon="wi:raindrops" size="1.5" /></div>
          <div class="tw-h-4" title="Wind"><Icon icon="fluent:weather-squalls-24-regular" size="1.5" /></div>
          <div class="tw-h-6"></div>
        </div>
        <div class="tw-w-2/12 tw-text-center tw-p-1">
          <div class="tw-h-10 tw-text-xs tw-font-kanit tw-font-medium"></div>
          <div class="tw-h-12" :title="forecast.daily.condition"><Icon :icon="getIcon(forecast.daily.code, true)" size="2" /></div>
          <div class="tw-h-8">{{ Math.floor(forecast.daily.temp).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ Math.floor(forecast.daily.feelsLike).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ forecast.daily.humidity }}%</div>
          <div class="tw-h-8">{{ Math.floor(forecast.daily.dewpoint).toFixed(0) }}°</div>
          <div class="tw-h-8">{{ forecast.daily.precip.toFixed(0) }}%</div>
          <div class="tw-h-4">{{ forecast.daily.windDirection }}</div>
          <div class="tw-h-6">
            <span v-if="forecast.daily.windGust.toFixed(0) > forecast.daily.windSpeed.toFixed(0)">{{ forecast.daily.windSpeed.toFixed(0) }}-{{ forecast.daily.windGust.toFixed(0) }}</span>
            <span v-else>{{ forecast.daily.windSpeed.toFixed(0) }}</span> mph
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from '@fantasypoints/slugify';
import Icon from '@/components/Icon.vue';
import { utcToZonedTime, format } from 'date-fns-tz';
import { mapWeatherIcon, formatUpdatedDate } from '@/utils';
export default {
  name: 'Weather',
  components: {
    Icon,
  },
  props: {
    forecast: Object,
    forecastUpdatedDate: String,
    options: {
      type: Object,
      require: false,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    lastUpdatedDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    singleTeam() {
      if (this.$props.options && this.$props.options.single && this.$props.team) {
        return this.$props.team === this.awayTeam.abbreviation ? this.awayTeam : this.homeTeam;
      }
      return false;
    },
    tag() {
      return `h${this.$props.options && this.$props.options.h ? this.$props.options.h : 3}`;
    },
    headingText() {
      if (this.$props.options && this.$props.options.title) return this.$props.options.title;
      return 'Weather Report';
    },
    headingSlug() {
      let text = `${this.headingText}-${this.$props.awayTeam.fullName}-vs-${this.$props.homeTeam.fullName}`
      if (this.singleTeam) {
        text = `${this.headingText}-${this.singleTeam.fullName}`;
      }
      return `h.${slugify.generate(text)}`;
    },
    withHeading() {
      return !this.$props.options || (this.$props.options && this.$props.options.h && this.$props.options.h !== false);
    },
    withDate() {
      if (this.$props.options && this.$props.options.withDate === false) return false;
      return true;
    },
    lastUpdated() {
      if (this.$props.lastUpdatedDate) {
        return `Updated ${formatUpdatedDate({ date: this.$props.lastUpdatedDate })} ET`;
      }
      return '';
    },
  },
  methods: {
    formatDate(params) {
      const date = new Date(params.date);
      const timeZone = 'America/New_York';
      const zonedDate = utcToZonedTime(date, timeZone);
      const pattern = 'h:mm';
      return format(zonedDate, pattern, { timeZone });
    },
    ampm(params) {
      const date = new Date(params.date);
      const timeZone = 'America/New_York';
      const zonedDate = utcToZonedTime(date, timeZone);
      const pattern = 'a';
      return format(zonedDate, pattern, { timeZone });
    },
    getIcon(code, isDay) {
      return mapWeatherIcon(code, isDay);
    },
  },
};
</script>

<style scoped lang="scss">
.fpw-weather-report {
  background-color: transparent;

  th,
  td {
    text-align: center;
  }
  table tr:nth-of-type(2n + 2) td {
    background: transparent;
  }
  .gametime-forecast {
    background-color: #eee;
  }
}
</style>
